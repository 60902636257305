import "./SuaMarca.css";

export default function SuaMarca() {
  return (
    <section className="sua-marca">
      <div className="container_sua-marca">
        <h1 className="titulo_sua-marca">mivê</h1>
        <h2 className="subtitulo_sua-marca">Sua marca visível no digital</h2>
      </div>
    </section>
  );
}
